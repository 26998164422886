<template>
  <el-dialog
    title="Адрес доставки"
    :visible="isShow"
    width="50%"
    :before-close="(event) => $emit('close', event)"
  >
    <el-scrollbar :class="$style.container">
      <el-form
        ref="form"
        :model="form"
        :class="$style.form"
        label-position="right"
        label-width="10rem"
        :rules="rules"
      >
        <el-form-item label="Город" prop="city">
          <el-select
            v-model="form.city"
            filterable
            placeholder="Выбрать"
            clearable
            :class="$style.select"
          >
            <el-option
              v-for="(item, index) in cities"
              :key="index"
              :label="item.text"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Улица" prop="street">
          <Autocomplete
            :search="form.street"
            placeholder="Поиск улицы"
            :valueNames="['value']"
            :class="$style.select"
            @querySearch="getAddressSuggests"
            @selectItem="handleSelectStreet"
            @input="form.street = $event"
          />
        </el-form-item>
        <el-form-item label="Квартира" prop="additionalInfo.flat">
          <el-input v-model="form.additionalInfo.flat"></el-input>
        </el-form-item>
        <el-form-item label="Подъезд">
          <el-input v-model="form.additionalInfo.entrance"></el-input>
        </el-form-item>
        <el-form-item label="Этаж">
          <el-input v-model="form.additionalInfo.floor"></el-input>
        </el-form-item>
        <el-form-item label="Частный дом">
          <el-checkbox
            v-model="form.additionalInfo.isDetachedHouse"
          ></el-checkbox>
        </el-form-item>
        <el-form-item label="Домофон">
          <el-input v-model="form.additionalInfo.intercom"></el-input>
        </el-form-item>
        <el-form-item label="Комментарий">
          <el-input
            v-model="form.comment"
            type="textarea"
            :rows="3"
            placeholder="Введите комментарий"
          >
          </el-input>
        </el-form-item>
        <div :class="$style.buttons">
          <el-button size="small" type="primary" @click="onSave">
            Сохранить адрес
          </el-button>
        </div>
      </el-form>
    </el-scrollbar>
  </el-dialog>
</template>

<script>
import cities from '@/assets/cities.json'
import delivery from '@/delivery'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
export default {
  components: { Autocomplete },
  props: {
    address: {
      city: {
        type: String,
        default: '',
      },
      street: {
        type: String,
        default: '',
      },
      flat: {
        type: String,
        default: '',
      },
      entrance: {
        type: String,
        default: '',
      },
      floor: {
        type: String,
        default: '',
      },
      intercom: {
        type: String,
        default: '',
      },
      comment: {
        type: String,
        default: '',
      },
      isDetachedHouse: {
        type: Boolean,
        default: false,
      },
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.form = { ...this.form, ...this.address }
  },
  computed: {
    cities() {
      return cities.map((city) => {
        return {
          text: city.city,
          value: city.city,
        }
      })
    },
  },
  data() {
    return {
      form: {
        city: '',
        street: '',
        additionalInfo: {
          flat: '',
          entrance: '',
          floor: '',
          intercom: '',
          isDetachedHouse: false,
        },
        comment: '',
        coordinates: {
          lat: null,
          lng: null,
        },
      },
      addressSuggests: [],
      rules: {
        street: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        city: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        additionalInfo: {
          flat: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  methods: {
    onSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return

        this.$emit('save', { ...this.form })
      })
    },
    handleChangeStreet() {
      this.getAddressSuggestsDebounce()
    },
    async getAddressSuggests({ setSearchItems }) {
      const { value, error } =
        await delivery.AddwineCore.DaDataActions.getAddressSuggests(
          this.form.city + ' ' + this.form.street,
        )

      if (error) return

      this.addressSuggests = value.suggestions
      setSearchItems(value.suggestions)
    },
    handleSelectStreet(selectedAddressData) {
      const { value } = selectedAddressData

      const titleIndex = value.indexOf(',')

      if (titleIndex >= 0) {
        const {
          data: { geo_lat, geo_lon },
          value,
        } = selectedAddressData

        this.form.street = value.slice(titleIndex + 1)
        this.form.coordinates = {
          lat: Number(geo_lat),
          lng: Number(geo_lon),
        }
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  height: 50vh;
  .form {
    padding: 1.5rem;
    .select {
      width: 100%;
    }
    .buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
